import { default as index4ESWB0qXEnMeta } from "/vercel/path0/pages/competition/[slug]/index.vue?macro=true";
import { default as influenceUnatHIt74KMeta } from "/vercel/path0/pages/competition/[slug]/influence.vue?macro=true";
import { default as legacyym8bnHpP6kMeta } from "/vercel/path0/pages/competition/[slug]/legacy.vue?macro=true";
import { default as registerOWiDKspy5dMeta } from "/vercel/path0/pages/competition/[slug]/register.vue?macro=true";
import { default as successiPsJVJDcBdMeta } from "/vercel/path0/pages/competition/[slug]/success.vue?macro=true";
import { default as gotinhaKK1ZvPj8u7Meta } from "/vercel/path0/pages/gotinha.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: index4ESWB0qXEnMeta?.name ?? "competition-slug",
    path: index4ESWB0qXEnMeta?.path ?? "/competition/:slug()",
    meta: index4ESWB0qXEnMeta || {},
    alias: index4ESWB0qXEnMeta?.alias || [],
    redirect: index4ESWB0qXEnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/competition/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: influenceUnatHIt74KMeta?.name ?? "competition-slug-influence",
    path: influenceUnatHIt74KMeta?.path ?? "/competition/:slug()/influence",
    meta: influenceUnatHIt74KMeta || {},
    alias: influenceUnatHIt74KMeta?.alias || [],
    redirect: influenceUnatHIt74KMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/competition/[slug]/influence.vue").then(m => m.default || m)
  },
  {
    name: legacyym8bnHpP6kMeta?.name ?? "competition-slug-legacy",
    path: legacyym8bnHpP6kMeta?.path ?? "/competition/:slug()/legacy",
    meta: legacyym8bnHpP6kMeta || {},
    alias: legacyym8bnHpP6kMeta?.alias || [],
    redirect: legacyym8bnHpP6kMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/competition/[slug]/legacy.vue").then(m => m.default || m)
  },
  {
    name: registerOWiDKspy5dMeta?.name ?? "competition-slug-register",
    path: registerOWiDKspy5dMeta?.path ?? "/competition/:slug()/register",
    meta: registerOWiDKspy5dMeta || {},
    alias: registerOWiDKspy5dMeta?.alias || [],
    redirect: registerOWiDKspy5dMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/competition/[slug]/register.vue").then(m => m.default || m)
  },
  {
    name: successiPsJVJDcBdMeta?.name ?? "competition-slug-success",
    path: successiPsJVJDcBdMeta?.path ?? "/competition/:slug()/success",
    meta: successiPsJVJDcBdMeta || {},
    alias: successiPsJVJDcBdMeta?.alias || [],
    redirect: successiPsJVJDcBdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/competition/[slug]/success.vue").then(m => m.default || m)
  },
  {
    name: gotinhaKK1ZvPj8u7Meta?.name ?? "gotinha",
    path: gotinhaKK1ZvPj8u7Meta?.path ?? "/gotinha",
    meta: gotinhaKK1ZvPj8u7Meta || {},
    alias: gotinhaKK1ZvPj8u7Meta?.alias || [],
    redirect: gotinhaKK1ZvPj8u7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/gotinha.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]